export enum RouteNames {
  Login = 'Login',
  Home = 'Dashboard',
  SetPassword = 'SetPassword',
  ResetPass = 'ResetPassword',
  Management = 'Lead Management',
  Settings = 'Settings',
  Users = 'Users & Roles',
  PlayersCards = 'Players Cards',
  CampaignManager = 'Campaign Manager',
  Reports = 'Reports',
  Segments = 'Segment Groups',
  CustomerJourney = 'Customer Journey Groups',
  Promotions = 'Promotions',
  Emails = 'Emails',
  EmailEvents = 'Email Events',
  PlaceHolders = 'Place Holders',
  EmailLogs = 'Email Logs',
  Wheel = 'Wheel',
  WheelSettings = 'Wheel Settings'
}

export enum PublicRoutes {
  Login = '/login',
  SetPassword = '/set-password',
  ResetPass = '/reset-password'
}

export enum AuthRoutes {
  Home = '/dashboard',
  Management = '/management',
  Settings = '/settings',
  Users = '/users',
  PlayersCards = '/players-cards',
  PlayersCardDetails = '/players-cards/:id',
  Reports = '/reports',
  Segments = '/segments',
  CustomerJourney = '/customer-journey',
  Emails = '/emails',
  EmailLogs = '/email-logs',
  PlaceHolders = '/place-holders',
  Promotions = '/promotions',
  Wheel = '/wheel'
}

export enum Restriction {
  Brand = 'Brand',
  User = 'User',
  Lead = 'Lead'
}
