import axios from 'axios';
import { baseUrl, getHttpHeaders } from '../utils/rights';
import { Email, EmailIsActive } from '../models/email';

const controller = 'email';

export const EmailEventsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/${controller}/GetAll/${brandId}`, getHttpHeaders());
};

export const EmailEventsIsActiveMutation = async (data: EmailIsActive) => {
  return await axios.put(`${baseUrl}/${controller}/isactive`, data, getHttpHeaders());
};

export const UpdateEmailEventMutation = async (data: Email) => {
  return await axios.put(`${baseUrl}/${controller}/update`, data, getHttpHeaders());
};

export const CreateEmailEventMutation = async (data: Email) => {
  return await axios.post(`${baseUrl}/${controller}/create`, data, getHttpHeaders());
};

export const DeleteEmailEventMutation = async (id: number) => {
  return await axios.delete(`${baseUrl}/${controller}/delete/${id}`, getHttpHeaders());
};

export const EmailLogsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/${controller}/logs/${brandId}`, getHttpHeaders());
};

export const PromoStatsQuery = async ({ brandId, id }: { brandId: number; id: number }) => {
  return await axios.get(
    `${baseUrl}/${controller}/brand/${brandId}/PromoStats/${id}`,
    getHttpHeaders()
  );
};

export const StageStatsQuery = async ({
  brandId,
  id,
  date
}: {
  brandId: number;
  id: number;
  date: string;
}) => {
  return await axios.get(
    `${baseUrl}/${controller}/brand/${brandId}/StageStats/${id}/${date}`,
    getHttpHeaders()
  );
};
