import React, { useContext, useEffect, useState } from 'react';
import DialogBox from '../../../Common/Layout/DialogBox';
import SelectComponent from '../../../Common/Fields/SelectComponent';
import { Box, SelectChangeEvent } from '@mui/material';
import { Journey, StageStatsDates } from '../../../../models/journeys';
import './JourneyStatsForm.scss';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../../enums/HttpRequestKeyEnums';
import { handleRequestError } from '../../../../utils/ui';
import { AppContext } from '../../../../AppContext';
import { JourneyStagesStatsDatesQuery } from '../../../../queries/journey';
import Loader from '../../../Common/Global/Loader';
import CancelButton from '../../../Common/Buttons/CancelButton';
import MainButton from '../../../Common/Buttons/MainButton';
import StageStats from '../StageStats/StageStats';

interface IProps {
  journey: Journey;
  onClose: () => void;
}

const JourneyStatsForm: React.FunctionComponent<IProps> = ({ onClose, journey }: IProps) => {
  const { dispatch } = useContext(AppContext);
  const stagesStatsQuery = useQuery({
    queryKey: [QueryKey.JourneyStagesDates, journey.id],
    queryFn: () => JourneyStagesStatsDatesQuery(journey.id),
    onSuccess: ({ data }) => {
      const res = data as StageStatsDates[];
      setStages(getStageNames(res));
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const getStageNames = (data: StageStatsDates[]) => {
    const statIds = data.map((d) => d.id);
    return journey.stages.filter((s) => statIds.includes(s.id)).map((s) => s.name);
  };

  const [selectedStage, setSelectedStage] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [stages, setStages] = useState<string[]>(
    stagesStatsQuery.data?.data
      ? getStageNames(stagesStatsQuery.data.data as StageStatsDates[])
      : []
  );
  const [dateOptions, setDateOptions] = useState<string[]>([]);
  const [stageId, setStageId] = useState<null | number>(null);
  const [openStatsWindow, setOpenStatsWindow] = useState(false);
  const allData = stagesStatsQuery.data?.data as StageStatsDates[];

  useEffect(() => {
    const stage = journey.stages.find((s) => s.name === selectedStage);
    setSelectedDate('');
    setDateOptions(allData?.find((d) => d.id === stage?.id ?? -1)?.dates ?? []);
  }, [selectedStage]);

  const handleStageChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    const stage = journey.stages.find((s) => s.name === value);
    setSelectedStage(stage?.name ?? '');
  };

  const handleDateChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;

    setSelectedDate(value as string);
  };

  const handleGetStats = () => {
    const stage = journey.stages.find((s) => s.name === selectedStage);
    if (stage) {
      setStageId(stage.id);
      setOpenStatsWindow(true);
    }
  };

  return (
    <DialogBox width={800} onClose={onClose} title={`${journey.name} stats`}>
      <Box className="journey-stats-row">
        <SelectComponent
          label="Stage"
          placeholder="Select Stage"
          isSingle
          hideNoneValue
          options={stages}
          value={selectedStage}
          onChange={handleStageChange}
          containerStyles={{ width: '100%' }}
        />
        <SelectComponent
          label="Date"
          placeholder="Select Date"
          isSingle
          options={dateOptions}
          value={selectedDate}
          onChange={handleDateChange}
          containerStyles={{ width: '100%' }}
        />
      </Box>
      <Box className="form-actions">
        <Box className="form-group-buttons">
          <CancelButton
            id="journey-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton
            id="journey-create"
            text="Get"
            onClick={handleGetStats}
            disabled={selectedStage.length === 0 || selectedDate.length === 0}
          />
        </Box>
      </Box>
      {openStatsWindow && (
        <StageStats
          id={stageId as number}
          name={selectedStage}
          date={selectedDate}
          onClose={() => {
            setStageId(null);
            setOpenStatsWindow(false);
          }}
        />
      )}
      <Loader loading={stagesStatsQuery.isLoading} />
    </DialogBox>
  );
};

export default JourneyStatsForm;
