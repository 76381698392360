import { Box, Link, IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import { IBrand, IWheelBrandModel } from '../../models/brand';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import { getCompanyBrandsQuery } from '../../queries/brand';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../AppContext';
import Loader from '../Common/Global/Loader';
import { handleRequestError } from '../../utils/ui';
import StatusIdicator from '../Common/Layout/StatusIdicator';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';

const WheelBrandsOverview: React.FunctionComponent = () => {
  const queryKey = [QueryKey.Brands];
  const { isLoading, data } = useQuery(queryKey, getCompanyBrandsQuery, {
    onSuccess: ({ data }) => {
      setBrands(data);
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const [brands, setBrands] = useState<IBrand[]>(data?.data || []);
  const { dispatch } = useContext(AppContext);

  const [devSecretVisibility, setDevSecretVisibility] = useState<Record<number, boolean>>({});
  const [prodSecretVisibility, setProdSecretVisibility] = useState<Record<number, boolean>>({});

  const toggleDevSecretVisibility = (id: number) => {
    setDevSecretVisibility((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const toggleProdSecretVisibility = (id: number) => {
    setProdSecretVisibility((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const maskSecret = (secret: string) => '•'.repeat(secret.length);

  const brandColumns = [
    {
      title: 'Brand',
      render: (data: IWheelBrandModel) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.name}
        </Link>
      )
    },
    {
      title: 'Dev Enabled',
      render: (data: IWheelBrandModel) => (
        <Box>
          <StatusIdicator type={data.wheelDevEnabled ? 'success' : 'fail'} />
          {data.wheelDevEnabled ? 'Yes' : 'No'}
        </Box>
      )
    },
    {
      title: 'Dev Id',
      field: 'wheelDevId'
    },
    {
      title: 'Dev Secret Key',
      render: (data: IWheelBrandModel) =>
        data.wheelDevSecret && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '8px' }}>
              {devSecretVisibility[data.wheelDevId]
                ? data.wheelDevSecret
                : maskSecret(data.wheelDevSecret)}
            </Box>
            <IconButton onClick={() => toggleDevSecretVisibility(data.wheelDevId)}>
              {devSecretVisibility[data.wheelDevId] ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
        )
    },
    {
      title: 'Prod Enabled',
      render: (data: IWheelBrandModel) => (
        <Box>
          <StatusIdicator type={data.wheelProdEnabled ? 'success' : 'fail'} />
          {data.wheelProdEnabled ? 'Yes' : 'No'}
        </Box>
      )
    },
    {
      title: 'Prod Id',
      field: 'wheelProdId'
    },
    {
      title: 'Prod Secret Key',
      render: (data: IWheelBrandModel) =>
        data.wheelProdSecret && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '8px' }}>
              {prodSecretVisibility[data.wheelProdId]
                ? data.wheelProdSecret
                : maskSecret(data.wheelProdSecret)}
            </Box>
            <IconButton onClick={() => toggleProdSecretVisibility(data.wheelProdId)}>
              {prodSecretVisibility[data.wheelProdId] ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
        )
    }
  ] as IHeadCell[];

  return (
    <Box className="nav-page">
      <CustomTable toolbarTitle={'Brands'} data={brands} columns={brandColumns} />
      <Loader loading={isLoading} />
    </Box>
  );
};

export default WheelBrandsOverview;
