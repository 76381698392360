import React from 'react';
import { Box } from '@mui/material';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';

type PlaceHolderModel = {
  name: string;
  usage: string;
};

const PlaceHoldersOverview: React.FunctionComponent = () => {
  const list = [
    {
      name: 'First Name',
      usage: '{{ firstName }}'
    },
    {
      name: 'Last Name',
      usage: '{{ lastName }}'
    },
    {
      name: 'Full Name',
      usage: '{{ fullName }}'
    },
    {
      name: 'Currency Code',
      usage: '{{ currency }}'
    },
    {
      name: 'Amount',
      usage: '{{ amount }}'
    },
    {
      name: 'Transaction Id',
      usage: '{{ transactionId }}'
    },
    {
      name: 'Payment Method',
      usage: '{{ paymentMethod }}'
    },
    {
      name: 'Player unsubscribe link',
      usage: '{{ unsubscribe }}'
    },
    {
      name: 'Player Loyalty Points Balance',
      usage: '{{ loyaltyPointsBalance }}'
    }
  ] as PlaceHolderModel[];

  const columns = [
    {
      title: 'Name',
      field: 'name'
    },
    {
      title: 'Usage',
      field: 'usage'
    }
  ] as IHeadCell[];

  return (
    <Box className="nav-page">
      <CustomTable
        toolbarTitle="List of Place Holders and usage examples"
        data={list}
        columns={columns}
      />
    </Box>
  );
};

export default PlaceHoldersOverview;
